import React from 'react';

import Layout from '../components/Layout';
import config from '../../config';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>{config.services_title}</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <p>{config.services_subHeading}</p>
          <ul className="alt">
          {config.services.map(service => {
                  const { name } = service;
                  return (
                    <li>{name}</li>
                  );
            })}
          </ul>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
